<template>
  <div class="user">
    <!-- 用户中心 -->
    <div class="userTitle">
      <div>用户中心</div>
    </div>
    <!-- 个人信息 -->
    <div class="userInfo">
      <div class="userInfo_avatar">
        <img :src="userInfo.pic" />
      </div>
      <div class="userInfo_name">
        <div>{{userInfo.username}}</div>
        <div>策划师</div>
      </div>
    </div>
    <!-- 个人数据 -->
    <div class="userInfo_data">
      <div class="userInfo_dataItem">
        <div>评论</div>
        <div>{{my_nums.comm_num}}</div>
      </div>
      <div class="userInfo_dataItem">
        <div>下载</div>
        <div>{{my_nums.down_num}}</div>
      </div>
      <div class="userInfo_dataItem">
        <div>点赞</div>
        <div>{{my_nums.like_num}}</div>
      </div>
      <div class="userInfo_dataItem">
        <div>观看</div>
        <div>{{my_nums.visit_num}}</div>
      </div>
    </div>
    <!-- 操作列表 -->
    <div class="userInfo_list">
      <div
        class="userInfo_listItem"
        v-for="(item,index) in list"
        :key="index"
        @click="signOut(item.path)"
      >
        <div>
          <div class="userInfo_listItem_icon">
            <i :class="item.icon"></i>
          </div>
          <div class="userInfo_listItem_name">{{item.name}}</div>
        </div>
        <div class="userInfo_listItem_push">
          <i :class="item.push"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 用户信息
      userInfo: "",
      // 用户数据
      my_nums: "",
      // 操作列表
      list: [
        {
          icon: "iconfont iconiconset0118",
          name: "我的作品",
          push: "el-icon-arrow-right",
          path: "/mMyWorks",
        },
        {
          icon: "iconfont iconwodeshoucang",
          name: "我的收藏",
          push: "el-icon-arrow-right",
          path: "/mMyKeep",
        },
        {
          icon: "iconfont iconzhuanti",
          name: "我的专题",
          push: "el-icon-arrow-right",
          path: "/mMyTopic",
        },
        {
          icon: "el-icon-collection-tag",
          name: "我的采集",
          push: "el-icon-arrow-right",
          path: "/mCollectUser",
        },
        {
          icon: "el-icon-switch-button",
          name: "安全退出",
          push: "el-icon-arrow-right",
          path: "/mLogin",
        },
      ],
    };
  },
  created() {
    this.getUserInfo();
    this.getUserData();
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      this.$axios({
        url: this.$api + "/auth/user/info",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.userInfo = res.data.data;
        }
      });
    },
    // 获取用户数据
    getUserData() {
      this.$axios({
        url: this.$api + "/auth/user/creation",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        params: {
          page: "",
          cat: "",
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.my_nums = res.data.data.my_nums;
        }
      });
    },
    // 退出登录
    signOut(path) {
      if (path == "/mLogin") {
        localStorage.removeItem("user_token");
        this.$router.push({
          path: "/mLogin",
        });
      } else {
        this.$router.push({
          path
        });
      }
    },
  },
};
</script>

<style scoped>
.user {
  background-color: #fff;
}
.userTitle {
  width: 100%;
  color: #000;
  padding: 3% 0;
  text-align: center;
  background-color: #fdd30f;
}
/* 用户信息 */
.userInfo {
  padding: 1rem 2rem;
  background-color: #fdd30f;
  display: flex;
  color: #000;
  justify-content: flex-start;
}
.userInfo_avatar {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 1rem;
}
.userInfo_avatar > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.userInfo_name > div:nth-of-type(2) {
  font-size: 10px;
  margin-top: 0.5rem;
  color: #000;
}
/* 个人数据 */
.userInfo_data {
  padding: 0.5rem 0;
  border-bottom: 1px solid #e3e3e3;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.userInfo_dataItem {
  font-size: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.userInfo_dataItem > div:nth-of-type(2) {
  margin-top: 0.5rem;
  font-size: 10px;
}
/* 操作列表 */
.userInfo_listItem {
  font-size: 16px;
  border-bottom: 1px solid #e3e3e3;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.userInfo_listItem_icon {
  margin-right: 2rem;
}
.userInfo_listItem_icon > i {
  font-size: 20px;
  color: #606266;
}
.userInfo_listItem > div:nth-of-type(1) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>